<template>
  <div class="settings__pages">
    <v-row>
      <v-col cols="12">
        <h2>Settings</h2>
      </v-col>
      <template v-for="(page, index) in pages">
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
          :key="index"
          v-if="page.router !== 'website.settings.payments' ? true : !enableRef"
        >
          <router-link
            class="text-decoration-none"
            :to="{
              name:
                page.router !== 'website.settings.payments' || utilities.checkPermisson(`payment_providers`, user)
                  ? page.router
                  : 'no-access',
            }"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card
                height="125"
                :elevation="hover ? 8 : 1"
                :class="{ 'on-hover': hover }"
                class="mx-auto card__background"
              >
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="2" xs="2" sm="2" md="2">
                      <v-icon>{{ page.icon }}</v-icon>
                    </v-col>
                    <v-col cols="10" xs="10" sm="10" md="10">
                      <h3 class="text-decoration-none">{{ page.title }}</h3>
                      <p class="text-decoration-none mt-3">{{ page.des }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-hover>
          </router-link>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import utilities from '@/helpers/utilities';
import { domainService } from '@/apis/domain.s';

export default {
  computed: {
    ...mapGetters(['user']),
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },

  data() {
    return {
      utilities,
      pages: [
        {
          icon: 'fas fa-cog',
          title: 'General',
          des: 'View and update your store details',
          router: 'website.settings.general',
        },
        {
          icon: 'far fa-credit-card',
          title: 'Payment providers',
          des: `Enable and manage your store's payment providers`,
          router: 'website.settings.payments',
        },
        {
          icon: 'fas fa-shopping-cart',
          title: 'Checkout',
          des: 'Customize your online checkout process',
          router: 'website.settings.checkout',
        },
        {
          icon: 'fas fa-user-circle',
          title: 'Account',
          des: 'Manage staff accounts and permissions',
          router: 'website.settings.account',
        },
        {
          icon: 'fas fa-shipping-fast',
          title: 'Shipping',
          des: 'Manage how you ship orders to customers',
          router: 'website.settings.shipping',
        },
        {
          icon: 'far fa-bell',
          title: 'Notification',
          des: 'Manage notifications sent to you and your customers',
          router: 'website.settings.notifications',
        },
        {
          icon: 'fas fa-balance-scale-right',
          title: 'Legal',
          des: `Manage your store's legal pages`,
          router: 'website.settings.legal',
        },
        {
          icon: 'fas fa-file-invoice-dollar',
          title: 'Billing',
          des: 'Manage your billing information and view your invoices',
          router: 'website.settings.billing',
        },
        {
          icon: 'fas fa-file-invoice-dollar',
          title: 'Taxes',
          des: 'Manage how your store charge taxes',
          router: 'website.settings.taxes',
        },
        {
          icon: 'fas fa-shopping-bag',
          title: 'Sale Channels',
          des: 'Manage the channels you use to sell your products and services',
          router: 'website.settings.sale-channels',
        },
        {
          icon: 'fas fa-shopping-bag',
          title: 'Proxy',
          des: 'Manage proxy',
          router: 'website.settings.proxy',
        },
      ],
      enableRef: false,
      paymentChoice: null,
    };
  },
  methods: {
    async checkEnableRef() {
      try {
        const { data } = await domainService.checkEnableRef();
        if (data) {
          this.enableRef = data[0].enableRef;
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:137 ~ checkEnableRef ~ error:', error);
      }
    },
  },

  mounted() {
    this.checkEnableRef();
    this.paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
    if (
      this.pagePreference &&
      this.pagePreference?.businessType === 'dropship' &&
      this.paymentChoice === 'latte' &&
      this.pagePreference?.isTrademark
    ) {
      this.pages.push({
        icon: 'fas fa-file-invoice-dollar',
        title: 'Payout',
        des: 'Manage payout with store Dropship',
        router: 'website.settings.payout',
      });
      this.pages.splice(1, 1);
    }
  },
  watch: {
    '$store.state.pagePreference.preference'() {
      this.paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
      if (
        this.pagePreference &&
        this.pagePreference.businessType === 'dropship' &&
        this.paymentChoice === 'latte' &&
        this.pagePreference?.isTrademark
      ) {
        this.pages.push({
          icon: 'fas fa-file-invoice-dollar',
          title: 'Payout',
          des: 'Manage payout with store Dropship',
          router: 'website.settings.payout',
        });
        this.pages.splice(1, 1);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.card__background {
  background-color: #efefef;
  &:hover {
    background-color: white;
  }
  transition: background 0.2s;
}
</style>
